.family-picker-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 110px;
    margin-top: 10px;
    justify-content: space-between;
}

.select-family-member {
    width: 100%;
    text-align: start;
}

.age-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}

.age-input-wrapper {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    margin-top: 5px;
}

.age-input {
    margin-left: 10px;
    margin-right: 10px;
}

.button-wrapper {
    margin-top: 40px;
}

.card-main-image {
    height: 100px;
    width: 100px;
}

.member-check-image {
    position: absolute;
    bottom: -8px;
    right: -8px;
}