.receipt-top {
    background-color: #F5F5F5;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin: 30px 0 20px;
    padding: 15px;
}

.receipt-top, .receipt-alert, .receipt-row {
    font-family: 'Muli', sans-serif;
    font-size: 12px;
    line-height: 140%;
}

.receipt-container {
    margin-left: 60px;
    margin-right: 60px;
    max-width: 980px;
}

.receipt-table {
    width: 100%;
    margin-top: 20px;
}

.receipt-table > tbody > tr:nth-of-type(odd) {
    background-color: #f5f5f5;
}

.receipt-row {
    border-left: 3px solid #183041;
    border-bottom: 1px solid #DFE0EB;
    height: 27px;
}

.receipt-row-img {
    width: 20px;
    height: auto;
    margin-right: 8px;
    margin-left: 8px;
}

.receipt-table-header,
.receipt-totals-row {
    font-family: 'Muli', sans-serif;
    font-weight: bold;
    font-size: 11px;
    line-height: 120%;
    height: 27px;
}

.receipt-totals-row {
    font-weight: normal;
    background-color: #fff !important;
}

.receipt-loading-container {
    position: relative;
    max-width: 100%;
    height: calc(100vh - 100px);
}

.receipt-error {
    text-align: center;
    margin-top: 120px;
    line-height: 200%;
}

@media screen and (max-width: 600px) {
    .receipt-top {
        flex-direction: column;
    }
    .receipt-copy {
        text-align: center;
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .receipt-container {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media print {
    .receipt-table > tbody > tr:nth-of-type(odd) {
        background-color: #f5f5f5;
        -webkit-print-color-adjust: exact;
    }
    .receipt-totals-row {
        background-color: #fff !important;
        -webkit-print-color-adjust: exact;
    }
    .receipt-top {
        background-color: #F5F5F5;
        -webkit-print-color-adjust: exact;
    }
}