.flex-col-center {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10%;
  }

  .signin-subheader {
      margin-bottom: 24px;
      color: #828282;
  }

  .signin-title-text {
      font-size: 24px;
      margin: 12px auto;
  }

  #request-code {
      cursor: pointer;
      color: #FF974C;
  }

  #request-code:hover {
    color: #BD6200;      
  }