body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-col-center {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10%;
  }

  .signin-subheader {
      margin-bottom: 24px;
      color: #828282;
  }

  .signin-title-text {
      font-size: 24px;
      margin: 12px auto;
  }

  #request-code {
      cursor: pointer;
      color: #FF974C;
  }

  #request-code:hover {
    color: #BD6200;      
  }
.family-picker-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 110px;
    margin-top: 10px;
    justify-content: space-between;
}

.select-family-member {
    width: 100%;
    text-align: start;
}

.age-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}

.age-input-wrapper {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    margin-top: 5px;
}

.age-input {
    margin-left: 10px;
    margin-right: 10px;
}

.button-wrapper {
    margin-top: 40px;
}

.card-main-image {
    height: 100px;
    width: 100px;
}

.member-check-image {
    position: absolute;
    bottom: -8px;
    right: -8px;
}
.simulator-body {
    max-width: 98%;
    margin-top: 120px;
}

.add-icon {
    height: 30px;
    width: 30px;
    margin-left: 10px;
}

.checkboxgroup {
    display: inline-block;
    text-align: center;
}

.checkboxgroup label {
    display: block;
}

.subtitle-text {
    font-size: 24px;
    font-family: 'Khula';
    font-weight: 400;
    color: #183041;
    flex: 1 1; 
}

.calc-wrapper {
    width: 250px;
    height: 80px;
    border: 1px solid #CECECE;
    border-radius: 5px;
}

.calc-title-wrapper {
    display: flex;
    justify-content: space-around;
    font-size: 12px;
    font-weight: 700;
}

.calc-separator {
    height: 1px;
    background-color: #00000080;
    margin-left: 0px;
    margin-right: 0px;
}

.calc-content-wrapper {
    display: flex;
    flex: 1 1;
    justify-content: space-around;
    align-items: center;
    font-size: 26px;
    font-weight: 400;
    color: #183041;
}

.body-row-wrapper {
    display: flex;
    flex-direction: 'row';
    width: 800px;
}

.full-col-flex-end {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
}

.update-button {
    width: 40%;
    margin: 40px 12px;
}
.loading-tp {
    margin-top: 40px;
    color: blue;
}

.loading-tp img {
    height: 40px;
}
.total-row {
    background-color: rgba(0, 255, 0, 0.2);
}

.pets-row {
    width: 300px;
    text-align: center;
}

.trash-header {
    font-weight: 600;
}
.brand-choice {
    padding-right: 12px;
}
.qr-generator-body {
    max-width: 98%;
    margin-top: 120px;
}

.table-wrapper {
    width: 80%;
    align-self: center;
    margin-top: 50px;
}

.products-table {
    font-size: 12px;
}

.title-text {
    margin-left: 50px;
    font-size: 30px;
    font-family: 'Khula';
    font-weight: 400;
    color: #183041;
    flex: 1 1;
}

.separator {
    height: 1px;
    background-color: #00000080;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 10px;
}

.btn-update {
    margin-top: 20px;
    margin-right: 50px;
    width: 198px;
    height: 40px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
    outline: none;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.spinner-update-container {
    margin-top: 20px;
    margin-right: 50px;
    width: 198px;
    height: 40px;
    outline: none;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
}

.spinner-grow {
    background-color: #183041;
}

.add-icon {
    height: 30px;
    width: 30px;
    margin-left: 10px;
}

.dialog-body-add-factor {
    display: flex;
    flex-direction: column;
}

.changes{
  font-size:.65rem;
  color: #808080;
  display: inline-block;
  vertical-align:top;
}
.simulator-col {
    width: 40%;
    margin-top: 20px;
}
.products-button {
    width: 240px;
    margin-left: 40px;
    margin-top: 12px;
    margin-bottom: -12px;
}
.order-link:hover {
    color: blue;
    cursor: pointer;
}
#customer-table{
    height: 200px;
    /* max-width: 60px; */
}

.product-select {
    padding-left: 4px;
    height: 40px;
    width: 180px;
}

.product-select.lg {
    width: 300px;
}

.dropoff-btn {
    margin-left: 40px;
}

.sms-input {
    width: 100%;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 3px;
    height: 200px;
    resize: none;
}
.section-title-text {
    font-size: 30px;
    font-family: 'Khula';
    font-weight: 400;
    color: #183041;
}

.section-subtitle-text, .section-subtitle-text-main {
    font-size: 24px;
    font-family: 'Khula';
    font-weight: 400;
    color: #183041;
}

.section-subtitle-text-main {
    color: #000;
    font-weight: 600;
}

.full-separator {
    height: 1px;
    background-color: #00000080;
    margin-top: 10px;
}

.separator {
    height: 1px;
    background-color: #00000080;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 10px;
}

.order-position{
    font-size: 20px;
    font-family: 'Khula';
    font-weight: 600;
    color: #183041;
}

.fill {
    display: flex;
    flex: 1 1;
}

.content {
    margin-left: 50px;
    margin-right: 50px;
}

.no-button:hover{
  color: #7ED321;
}

.body-survey {
    display: flex;
    flex: 2 1;
}

.body-factors {
    display: flex;
    flex: 3 1;
}

.col-space {
    width: 30px;
}

.body-results {
    margin-top: 30px;
    margin-bottom: 100px;
}

.totals-factor-title-text {
    margin-top: 35px;
}

.totals-title-text {
    margin-top: 20px;
}

.totals-text, .totals-text-recommended {
    margin-top: 20px;
    display: flex;
    flex: 1 1;
    justify-content: center;
    font-size: 16px;
    font-family: 'Khula';
    font-weight: 600;
    color: #000;
}

.totals-text-recommended {
    color: #828282;
}

.totals-calc-row{
    margin-top: 24px;
    width: 100%;
    text-align: center;
    border-radius: 20px;
    border: 4px solid #183041;
    border-top: 2px solid #183041;
}

.factor-calc-row {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.totals-bottom {
    margin-bottom: 16px;
}

.order-table {
    width: 100%;
}

.order-table-head {
    font-size: 20px;
    border-bottom: 1px solid #000;
}
.receipt-top {
    background-color: #F5F5F5;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin: 30px 0 20px;
    padding: 15px;
}

.receipt-top, .receipt-alert, .receipt-row {
    font-family: 'Muli', sans-serif;
    font-size: 12px;
    line-height: 140%;
}

.receipt-container {
    margin-left: 60px;
    margin-right: 60px;
    max-width: 980px;
}

.receipt-table {
    width: 100%;
    margin-top: 20px;
}

.receipt-table > tbody > tr:nth-of-type(odd) {
    background-color: #f5f5f5;
}

.receipt-row {
    border-left: 3px solid #183041;
    border-bottom: 1px solid #DFE0EB;
    height: 27px;
}

.receipt-row-img {
    width: 20px;
    height: auto;
    margin-right: 8px;
    margin-left: 8px;
}

.receipt-table-header,
.receipt-totals-row {
    font-family: 'Muli', sans-serif;
    font-weight: bold;
    font-size: 11px;
    line-height: 120%;
    height: 27px;
}

.receipt-totals-row {
    font-weight: normal;
    background-color: #fff !important;
}

.receipt-loading-container {
    position: relative;
    max-width: 100%;
    height: calc(100vh - 100px);
}

.receipt-error {
    text-align: center;
    margin-top: 120px;
    line-height: 200%;
}

@media screen and (max-width: 600px) {
    .receipt-top {
        flex-direction: column;
    }
    .receipt-copy {
        text-align: center;
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .receipt-container {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media print {
    .receipt-table > tbody > tr:nth-of-type(odd) {
        background-color: #f5f5f5;
        -webkit-print-color-adjust: exact;
    }
    .receipt-totals-row {
        background-color: #fff !important;
        -webkit-print-color-adjust: exact;
    }
    .receipt-top {
        background-color: #F5F5F5;
        -webkit-print-color-adjust: exact;
    }
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
    font-family: 'Danley';
    src: url(/static/media/Danley.b4a93b85.woff2) format('woff2'),
         url(/static/media/Danley.6d5ebf2d.woff) format('woff'),
         url(/static/media/Danley.20c76e36.otf) format('opentype')
}
  
@font-face {
    font-family: 'Danley-ExtraBold';
    src: url(/static/media/Danley-ExtraBold.8691ab9a.woff2) format('woff2'),
        url(/static/media/Danley-ExtraBold.ca6a3e57.woff) format('woff'),
        url(/static/media/Danley-ExtraBold.8921f957.otf) format('opentype')
}

@font-face {
    font-family: 'Danley-Bold';
    src: url(/static/media/Danley-Bold.a3c85e2a.woff2) format('woff2'),
        url(/static/media/Danley-Bold.42017557.woff) format('woff'),
        url(/static/media/Danley-Bold.dc7c7ace.otf) format('opentype')
}

body {
    overflow: scroll;
    font-family: 'Muli', sans-serif;
}
button {
    cursor: pointer;
}
.red {
    color: red !important;
}
.link {
    color: blue !important;
    text-decoration: underline !important;
    cursor: pointer !important;
}
.link:hover {
    text-decoration: none;
}

.link.red:hover {
    color: #cc0000 !important;
}

.green {
    color: green;
}

.blue {
    color: blue;
}

.blue:hover{
    cursor: pointer;
    color: goldenrod;
}

.pointer {
    cursor: pointer;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-center {
    justify-content: center;
}

.flex-self-center {
    align-self: center;
}

.flex-space-around {
    justify-content: space-around
}

.flex-space-between {
    justify-content: space-between;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-nowrap {
    white-space: nowrap;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase, .initialism {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.btn {
    margin: 2px;
}

.btn-primary {
    background: #2d5a7a;
    color: #fff;
    border: 2px solid #2d5a7a;
    outline: none;
    cursor: pointer;
}

.btn-danger {
    background: red;
    color: #fff;
    border: 2px solid red;
    outline: none;
    cursor: pointer;
}

.btn-danger:hover {
    background: #cc0000;
    border: 2px solid #cc0000;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    background: #183041 !important;
    border-color: #183041 !important;
    outline: none;
}

.btn-primary.btn-outline {
    background: transparent;
    color: #183041;
    border: 2px solid #183041;
}

.btn-primary.btn-outline:hover, .btn-primary.btn-outline:focus, .btn-primary.btn-outline:active {
    background: #183041;
    color: #fff;
}

.top-space-30 {
    margin-top: 30px;
}

.top-space-40 {
    margin-top: 40px;
}

.modal-container {
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.80);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert-dialog-small {
    height: 250px;
    width: 350px;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 20px;
}

.alert-dialog-medium {
    height: 400px;
    width: 500px;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 20px;
}

.alert-dialog-short {
    height: 250px;
    width: 500px;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 20px;
}

.alert-dialog-large {
    max-height: 100vh;
    overflow-y: auto;
    height: 640px;
    width: 500px;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 20px;
}

.refund-container {
    width: 650px;
    max-height: 95vh;
    max-width: 95%;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 20px;
    overflow-y: auto;
}

.refund-totals-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 30px;
    margin-bottom: 30px;
}

#refund-memo-container {
    margin: 0 auto 42px;
}

#refund-memo-input {
    width: 100%;
    border: 1px solid gray;
}

#refund-memo-invalid {
    height: 30px;
    color: red;
    margin-top: 12px;
}

#refund-memo-input.invalid {
    border: 1px solid red;
}

.refund-select-row.partial {
    background-color: #FFFACC;
}

.refund-select-row.full {
    background-color: #E4F1FF;
}

.dialog-title-text {
    margin-top: 20px;
    font-size: 28px;
    font-family: 'Khula';
    font-weight: 400;
}

.dialog-body-text {
    margin-top: 20px;
    font-size: 24px;
    font-family: 'Khula';
    font-weight: 400;
    color: #000000;
    flex: 1 1;
}

.dialog-btn-ok {
    width: 198px;
    height: 40px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.dialog-btn-ok:disabled {
    opacity: 0.7;
}
.error-color {
    color: #FF0000;
}

.success-color {
    color: #87A061;
}

.rounded-wrapper {
    border-radius: 6px;
    border-style: solid;
    position: relative;
    background: rgba(255, 255, 255, 0);
    border: 1px solid #CBCBCB;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    /*margin: 1em;*/
}

.editable-cell {
    height: 25px;
    width: 35px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(56, 129, 172);
    border-radius: 4px;
}

.row-icon {
    height: 20px;
    width: 20px;
    opacity: 0.55;
}

.product-thumbnail{
  text-align: center;
}

.product-thumbnail img{
  height: 40px;
}

a:hover .row-icon {
    opacity: 1;
}

.no-button, .no-button:focus {
    border: none;
    background-color: transparent;
    outline: 0;
}

.button-row{
    display: flex;
    justify-content: space-between;
}
.button-row button{
    cursor: pointer;
}

.prevButton {
    margin-left: 30px;
    font-size: 18px;
    font-family: 'Khula';
    font-weight: 600;
    color: #183041;
    margin-bottom: 30px;
    width: 200px;
}

.nextButton {
    margin-right: 30px;
    font-size: 18px;
    font-family: 'Khula';
    font-weight: 600;
    color: #183041;
    margin-bottom: 30px;
    width: 150px;
}

.hide {
    display: none;
}

.btn-sm {
    max-width: 120px;
}

.header-navbar {
    height: 80px;
    margin-bottom: 40px;
}

.header-logo {
    margin-top: -18px;
}

.secondary-navbar {
    top: 80px;
    margin-bottom: 40px;
    z-index: 999 !important;
}

.sdrop-body {
    margin-top: 120px;
}

.sdrop-body.short {
    margin-top: 80px;
}

.navbar-nav {
    text-align: right;
    flex-direction: column;
    border-radius: 4px;
    padding-right: .5rem;
}

.product-add-container {
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.product-add-container>select,
.counter-body {
    margin: 10px auto;
}

.counter-body {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.counter-button {
    font-size: 14px;
    width: 30px;
    height: 30px;
    margin: 0 12px;
    padding-top: 1px; 
    color: #1F90FE;
    border-radius: 100px;
    text-align: center;
    cursor: pointer;
    background: #FFFFFF;
    border: 2px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 50px;
}

.counter-button:hover {
    background-color: #1F90FE;
    color: #fff;
}

.counter-value {
    font-size: 18px;
    min-width: 48px;
    text-align: center;
}


@media screen and (max-width: 991px) {
    .dropdown-menu.show {
        text-align: right;
    }
}
tr.no-border {
    border-top: 3px solid #fff;
}

.space-bottom {
    margin-bottom: 20px;
}

.alert-small {
    font-size: 11px;
}
.table-product-img > img {
    max-height: 60px;
    width: auto;
}

.product-row {
    background-color: #dceeff;
}
.extra-margin-top {
    margin-top: 160px;
}

.modal-button-col {
    height: 150px;
}
#home-container {
    margin: 120px auto;
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
}
#quick-stats {
    margin-left: 15px;
}
#quick-stats > div:hover {
    color: blue;
    cursor: pointer;
}

.report-btn-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 12px auto;
}

.gold {
    color: goldenrod;
}

.customers-table-header{
    margin-top: 40px;
    margin-bottom: 12px;
}

.paw-print {
    font-size: 14px;
    color: #183041;
    opacity: 0.8;
    margin-right: 12px;
    margin-left: 12px;
    padding-top: 12px;
}

.packing-slip-details > .row {
    word-wrap: break-word;
    word-break: break-word;
}

.reports-container {
    width: 900px;
    max-width: 95vw;
    margin-left: auto;
    margin-right: auto;
}
