.simulator-body {
    max-width: 98%;
    margin-top: 120px;
}

.add-icon {
    height: 30px;
    width: 30px;
    margin-left: 10px;
}

.checkboxgroup {
    display: inline-block;
    text-align: center;
}

.checkboxgroup label {
    display: block;
}

.subtitle-text {
    font-size: 24px;
    font-family: 'Khula';
    font-weight: 400;
    color: #183041;
    flex: 1; 
}

.calc-wrapper {
    width: 250px;
    height: 80px;
    border: 1px solid #CECECE;
    border-radius: 5px;
}

.calc-title-wrapper {
    display: flex;
    justify-content: space-around;
    font-size: 12px;
    font-weight: 700;
}

.calc-separator {
    height: 1px;
    background-color: #00000080;
    margin-left: 0px;
    margin-right: 0px;
}

.calc-content-wrapper {
    display: flex;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    font-size: 26px;
    font-weight: 400;
    color: #183041;
}

.body-row-wrapper {
    display: flex;
    flex-direction: 'row';
    width: 800px;
}

.full-col-flex-end {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.update-button {
    width: 40%;
    margin: 40px 12px;
}
.loading-tp {
    margin-top: 40px;
    color: blue;
}

.loading-tp img {
    height: 40px;
}
.total-row {
    background-color: rgba(0, 255, 0, 0.2);
}

.pets-row {
    width: 300px;
    text-align: center;
}

.trash-header {
    font-weight: 600;
}
.brand-choice {
    padding-right: 12px;
}