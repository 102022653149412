.section-title-text {
    font-size: 30px;
    font-family: 'Khula';
    font-weight: 400;
    color: #183041;
}

.section-subtitle-text, .section-subtitle-text-main {
    font-size: 24px;
    font-family: 'Khula';
    font-weight: 400;
    color: #183041;
}

.section-subtitle-text-main {
    color: #000;
    font-weight: 600;
}

.full-separator {
    height: 1px;
    background-color: #00000080;
    margin-top: 10px;
}

.separator {
    height: 1px;
    background-color: #00000080;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 10px;
}

.order-position{
    font-size: 20px;
    font-family: 'Khula';
    font-weight: 600;
    color: #183041;
}

.fill {
    display: flex;
    flex: 1;
}

.content {
    margin-left: 50px;
    margin-right: 50px;
}

.no-button:hover{
  color: #7ED321;
}

.body-survey {
    display: flex;
    flex: 2;
}

.body-factors {
    display: flex;
    flex: 3;
}

.col-space {
    width: 30px;
}

.body-results {
    margin-top: 30px;
    margin-bottom: 100px;
}

.totals-factor-title-text {
    margin-top: 35px;
}

.totals-title-text {
    margin-top: 20px;
}

.totals-text, .totals-text-recommended {
    margin-top: 20px;
    display: flex;
    flex: 1;
    justify-content: center;
    font-size: 16px;
    font-family: 'Khula';
    font-weight: 600;
    color: #000;
}

.totals-text-recommended {
    color: #828282;
}

.totals-calc-row{
    margin-top: 24px;
    width: 100%;
    text-align: center;
    border-radius: 20px;
    border: 4px solid #183041;
    border-top: 2px solid #183041;
}

.factor-calc-row {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.totals-bottom {
    margin-bottom: 16px;
}

.order-table {
    width: 100%;
}

.order-table-head {
    font-size: 20px;
    border-bottom: 1px solid #000;
}