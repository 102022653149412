@font-face {
    font-family: 'Danley';
    src: url('./fonts/Danley.woff2') format('woff2'),
         url('./fonts/Danley.woff') format('woff'),
         url('./fonts/Danley.otf') format('opentype')
}
  
@font-face {
    font-family: 'Danley-ExtraBold';
    src: url('./fonts/Danley-ExtraBold.woff2') format('woff2'),
        url('./fonts/Danley-ExtraBold.woff') format('woff'),
        url('./fonts/Danley-ExtraBold.otf') format('opentype')
}

@font-face {
    font-family: 'Danley-Bold';
    src: url('./fonts/Danley-Bold.woff2') format('woff2'),
        url('./fonts/Danley-Bold.woff') format('woff'),
        url('./fonts/Danley-Bold.otf') format('opentype')
}

body {
    overflow: scroll;
    font-family: 'Muli', sans-serif;
}
button {
    cursor: pointer;
}
.red {
    color: red !important;
}
.link {
    color: blue !important;
    text-decoration: underline !important;
    cursor: pointer !important;
}
.link:hover {
    text-decoration: none;
}

.link.red:hover {
    color: #cc0000 !important;
}

.green {
    color: green;
}

.blue {
    color: blue;
}

.blue:hover{
    cursor: pointer;
    color: goldenrod;
}

.pointer {
    cursor: pointer;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-center {
    justify-content: center;
}

.flex-self-center {
    align-self: center;
}

.flex-space-around {
    justify-content: space-around
}

.flex-space-between {
    justify-content: space-between;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-nowrap {
    white-space: nowrap;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase, .initialism {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.btn {
    margin: 2px;
}

.btn-primary {
    background: #2d5a7a;
    color: #fff;
    border: 2px solid #2d5a7a;
    outline: none;
    cursor: pointer;
}

.btn-danger {
    background: red;
    color: #fff;
    border: 2px solid red;
    outline: none;
    cursor: pointer;
}

.btn-danger:hover {
    background: #cc0000;
    border: 2px solid #cc0000;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    background: #183041 !important;
    border-color: #183041 !important;
    outline: none;
}

.btn-primary.btn-outline {
    background: transparent;
    color: #183041;
    border: 2px solid #183041;
}

.btn-primary.btn-outline:hover, .btn-primary.btn-outline:focus, .btn-primary.btn-outline:active {
    background: #183041;
    color: #fff;
}

.top-space-30 {
    margin-top: 30px;
}

.top-space-40 {
    margin-top: 40px;
}

.modal-container {
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.80);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert-dialog-small {
    height: 250px;
    width: 350px;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 20px;
}

.alert-dialog-medium {
    height: 400px;
    width: 500px;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 20px;
}

.alert-dialog-short {
    height: 250px;
    width: 500px;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 20px;
}

.alert-dialog-large {
    max-height: 100vh;
    overflow-y: auto;
    height: 640px;
    width: 500px;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 20px;
}

.refund-container {
    width: 650px;
    max-height: 95vh;
    max-width: 95%;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 20px;
    overflow-y: auto;
}

.refund-totals-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 30px;
    margin-bottom: 30px;
}

#refund-memo-container {
    margin: 0 auto 42px;
}

#refund-memo-input {
    width: 100%;
    border: 1px solid gray;
}

#refund-memo-invalid {
    height: 30px;
    color: red;
    margin-top: 12px;
}

#refund-memo-input.invalid {
    border: 1px solid red;
}

.refund-select-row.partial {
    background-color: #FFFACC;
}

.refund-select-row.full {
    background-color: #E4F1FF;
}

.dialog-title-text {
    margin-top: 20px;
    font-size: 28px;
    font-family: 'Khula';
    font-weight: 400;
}

.dialog-body-text {
    margin-top: 20px;
    font-size: 24px;
    font-family: 'Khula';
    font-weight: 400;
    color: #000000;
    flex: 1;
}

.dialog-btn-ok {
    width: 198px;
    height: 40px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.dialog-btn-ok:disabled {
    opacity: 0.7;
}
.error-color {
    color: #FF0000;
}

.success-color {
    color: #87A061;
}

.rounded-wrapper {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    border-radius: 6px;
    border-style: solid;
    position: relative;
    background: rgba(255, 255, 255, 0);
    border: 1px solid #CBCBCB;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    /*margin: 1em;*/
}

.editable-cell {
    height: 25px;
    width: 35px;
    text-align: center;
    border: 1px solid;
    border-color: rgb(56, 129, 172);
    border-radius: 4px;
}

.row-icon {
    height: 20px;
    width: 20px;
    opacity: 0.55;
}

.product-thumbnail{
  text-align: center;
}

.product-thumbnail img{
  height: 40px;
}

a:hover .row-icon {
    opacity: 1;
}

.no-button, .no-button:focus {
    border: none;
    background-color: transparent;
    outline: 0;
}

.button-row{
    display: flex;
    justify-content: space-between;
}
.button-row button{
    cursor: pointer;
}

.prevButton {
    margin-left: 30px;
    font-size: 18px;
    font-family: 'Khula';
    font-weight: 600;
    color: #183041;
    margin-bottom: 30px;
    width: 200px;
}

.nextButton {
    margin-right: 30px;
    font-size: 18px;
    font-family: 'Khula';
    font-weight: 600;
    color: #183041;
    margin-bottom: 30px;
    width: 150px;
}

.hide {
    display: none;
}

.btn-sm {
    max-width: 120px;
}

.header-navbar {
    height: 80px;
    margin-bottom: 40px;
}

.header-logo {
    margin-top: -18px;
}

.secondary-navbar {
    top: 80px;
    margin-bottom: 40px;
    z-index: 999 !important;
}

.sdrop-body {
    margin-top: 120px;
}

.sdrop-body.short {
    margin-top: 80px;
}

.navbar-nav {
    text-align: right;
    flex-direction: column;
    border-radius: 4px;
    padding-right: .5rem;
}

.product-add-container {
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.product-add-container>select,
.counter-body {
    margin: 10px auto;
}

.counter-body {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.counter-button {
    font-size: 14px;
    width: 30px;
    height: 30px;
    margin: 0 12px;
    padding-top: 1px; 
    color: #1F90FE;
    border-radius: 100px;
    text-align: center;
    cursor: pointer;
    background: #FFFFFF;
    border: 2px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 50px;
}

.counter-button:hover {
    background-color: #1F90FE;
    color: #fff;
}

.counter-value {
    font-size: 18px;
    min-width: 48px;
    text-align: center;
}


@media screen and (max-width: 991px) {
    .dropdown-menu.show {
        text-align: right;
    }
}
tr.no-border {
    border-top: 3px solid #fff;
}

.space-bottom {
    margin-bottom: 20px;
}

.alert-small {
    font-size: 11px;
}
.table-product-img > img {
    max-height: 60px;
    width: auto;
}

.product-row {
    background-color: #dceeff;
}
.extra-margin-top {
    margin-top: 160px;
}

.modal-button-col {
    height: 150px;
}
#home-container {
    margin: 120px auto;
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
}
#quick-stats {
    margin-left: 15px;
}
#quick-stats > div:hover {
    color: blue;
    cursor: pointer;
}

.report-btn-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 12px auto;
}

.gold {
    color: goldenrod;
}

.customers-table-header{
    margin-top: 40px;
    margin-bottom: 12px;
}

.paw-print {
    font-size: 14px;
    color: #183041;
    opacity: 0.8;
    margin-right: 12px;
    margin-left: 12px;
    padding-top: 12px;
}

.packing-slip-details > .row {
    word-wrap: break-word;
    word-break: break-word;
}

.reports-container {
    width: 900px;
    max-width: 95vw;
    margin-left: auto;
    margin-right: auto;
}