.table-wrapper {
    width: 80%;
    align-self: center;
    margin-top: 50px;
}

.products-table {
    font-size: 12px;
}

.title-text {
    margin-left: 50px;
    font-size: 30px;
    font-family: 'Khula';
    font-weight: 400;
    color: #183041;
    flex: 1;
}

.separator {
    height: 1px;
    background-color: #00000080;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 10px;
}

.btn-update {
    margin-top: 20px;
    margin-right: 50px;
    width: 198px;
    height: 40px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
    outline: none;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.spinner-update-container {
    margin-top: 20px;
    margin-right: 50px;
    width: 198px;
    height: 40px;
    outline: none;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
}

.spinner-grow {
    background-color: #183041;
}

.add-icon {
    height: 30px;
    width: 30px;
    margin-left: 10px;
}

.dialog-body-add-factor {
    display: flex;
    flex-direction: column;
}

.changes{
  font-size:.65rem;
  color: #808080;
  display: inline-block;
  vertical-align:top;
}
.simulator-col {
    width: 40%;
    margin-top: 20px;
}
.products-button {
    width: 240px;
    margin-left: 40px;
    margin-top: 12px;
    margin-bottom: -12px;
}
.order-link:hover {
    color: blue;
    cursor: pointer;
}