#customer-table{
    height: 200px;
    /* max-width: 60px; */
}

.product-select {
    padding-left: 4px;
    height: 40px;
    width: 180px;
}

.product-select.lg {
    width: 300px;
}

.dropoff-btn {
    margin-left: 40px;
}

.sms-input {
    width: 100%;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 3px;
    height: 200px;
    resize: none;
}